// extracted by mini-css-extract-plugin
export var AuthorIntroContainer = "AuthorIntro-module--AuthorIntroContainer--sL24z";
export var authorGithub = "AuthorIntro-module--authorGithub--jYrG8";
export var authorImage = "AuthorIntro-module--authorImage--s7QzZ";
export var authorImageContainer = "AuthorIntro-module--authorImageContainer--ZYiKa";
export var backgroundCircles = "AuthorIntro-module--backgroundCircles--glThq";
export var contactButton = "AuthorIntro-module--contactButton--3eeX7";
export var heroBackgroundCircleLeft = "AuthorIntro-module--heroBackgroundCircleLeft--uHoVZ";
export var heroBackgroundInnerCircleLeft = "AuthorIntro-module--heroBackgroundInnerCircleLeft--XTzA8";
export var heroWhiteBlurCirce = "AuthorIntro-module--heroWhiteBlurCirce--w+Rc9";
export var introDescriptionButtonSection = "AuthorIntro-module--introDescriptionButtonSection--jL3op";
export var introSectionContainer = "AuthorIntro-module--introSectionContainer--24OUg";
export var introSectionOuter = "AuthorIntro-module--introSectionOuter--DDDBU";
export var introSectionText = "AuthorIntro-module--introSectionText--J7eSy";