import React, { FC } from "react";
import * as styles from "./SingleAuthor.module.scss";
import AuthorIntro from "../AuthorIntro/AuthorIntro";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import moment from "moment";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { pathWithoutSpace } from "../../ReportsUiComponents/ReportsMainHelpers";
import { ImageUrlCheck } from "../../Shared/ImageUrlCheck/ImageUrlCheck";
import HeroBannerMobile from "../../Shared/HeroBannerMobile/HeroBannerMobile";
import CircleGreenBlur from "../../Shared/CircleOutlineAndBlur/Circlegreenblur";
const BlockContent = require("@sanity/block-content-to-react");

type Props = {
  data: any;
};

const SingleAuthor: FC<Props> = ({ data }) => {
  const width = UseWindowSize();
  const posts = data.posts;
  const posts2 = data.posts2;
  const allposts: any[] = [];
  posts.edges.map((i: any) => {
    allposts.push(i);
  });
  posts2.edges.map((i: any) => {
    allposts.push(i);
  });

  return (
    <div className={styles.singleAuthors}>
      {width.width < 1200 ? (
        <HeroBannerMobile
          heading={data.author.name}
          description={data.author.position}
          homeBackground={ImageUrlCheck(data.author._rawImage)}
          buttonText=""
          date=""
          linkedIn={data.author.linkedin}
          github={data.author.github}
          alt={data?.alt}
          link={"/contact"}
        />
      ) : (
        <AuthorIntro
          heading={data.author.name}
          description={data.author.position}
          homeBackground={ImageUrlCheck(data.author._rawImage)}
          buttonText=""
          date=""
          linkedIn={data.author.linkedin}
          github={data.author.github}
        />
      )}

      <InnerWrapper>
        <div className={styles.singleAuthorsContent}>
          <div className={styles.singleAuthorAbout}>
            <h3>.About</h3>
            <BlockContent blocks={data.author.about} serializers={{}} />
          </div>
          {allposts.length && (
            <div>
              <h3>.Blogposts</h3>
              <div className={styles.singleAuthorBlogs}>
                <div className={styles.singleAuthorPosts}>
                  {allposts.map(({ node }: any) => (
                    <Link
                      to={`/${node.blogType[0].title}/${pathWithoutSpace(
                        node.title
                      )}`}
                      key={node.title}
                    >
                      <div className={styles.postItem}>
                        <div className={styles.postDate}>
                          <p>{moment(node.publishedAt).format("ll")}</p>

                          <motion.img
                            height="auto"
                            width="auto"
                            src={arrowRightWhite}
                            alt="blog"
                          />
                        </div>
                        <p className={styles.postTitle}>{node.title}</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}

          <CircleGreenBlur
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleGreen}
          />
        </div>
      </InnerWrapper>
    </div>
  );
};

export default SingleAuthor;
