import React, { FC, useState } from "react";
import { motion } from "framer-motion";
import * as styles from "./AuthorIntro.module.scss";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import { FiCalendar, FiClock } from "react-icons/fi";

import moment from "moment";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import InnerWrapper from "../../Shared/InnerWrapper/InnerWrapper";
import {
  AUTHOR_DATE_COLOR_GREEN,
  AUTHOR_SOCIAL_ICON_COLOR_WHITE,
} from "./AuthorIntro.helper";

type Props = {
  heading: string;
  description: string;
  homeBackground: any;
  buttonText: string;
  date: string;
  link?: string;
  linkedIn: string;
  github: string;
};

const AuthorIntro: FC<Props> = ({
  heading,
  description,
  homeBackground,
  date,
  linkedIn,
  github,
}) => {
  const [htmlElementsZindex] = useState<boolean>(false);

  return (
    <InnerWrapper>
      <div className={styles.AuthorIntroContainer}>
        <div className={styles.introSectionOuter}>
          <div className={styles.introSectionContainer}>
            <div
              className={styles.introSectionText}
              style={{ zIndex: htmlElementsZindex ? 0 : 10000 }}
            >
              {date && (
                <motion.p>
                  <span>
                    <FiCalendar color={AUTHOR_DATE_COLOR_GREEN} />
                    &nbsp; {moment(date).format("MMM Do YYYY")}
                  </span>

                  <span>
                    <FiClock color={AUTHOR_DATE_COLOR_GREEN} />
                    &nbsp;At {moment(date).format("HH:mm")} hrs.
                  </span>
                </motion.p>
              )}

              <motion.h1
                style={{ willChange: "height" }}
                initial={{ height: 0, y: 70 }}
                animate={{ height: "auto", y: 0 }}
                exit={{ height: 0, y: 70 }}
                transition={{ type: "easeOut", duration: 1.2 }}
              >
                .{heading}
              </motion.h1>

              <motion.div
                style={{ willChange: "opacity" }}
                initial={{ opacity: 0, visibility: "hidden" }}
                animate={{ opacity: 1, visibility: "visible" }}
                transition={{ ease: "easeIn", duration: 0.9, delay: 1.5 }}
                className={styles.introDescriptionButtonSection}
              >
                <motion.span
                  initial={{ visibility: "hidden", scale: 0.7 }}
                  animate={{ visibility: "visible", scale: 1 }}
                  transition={{ ease: "easeIn", duration: 0.5 }}
                >
                  {description}
                </motion.span>

                <div>
                  {linkedIn && (
                    <a href={linkedIn} target="_blank" rel="noreferrer">
                      <FaLinkedinIn
                        color={AUTHOR_SOCIAL_ICON_COLOR_WHITE}
                        size={22}
                      />
                    </a>
                  )}
                  {github !== null && (
                    <a
                      href={github}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.authorGithub}
                    >
                      <FaGithub
                        color={AUTHOR_SOCIAL_ICON_COLOR_WHITE}
                        size={20}
                      />
                    </a>
                  )}
                </div>
              </motion.div>
            </div>
          </div>
          <div className={styles.authorImageContainer}>
            <img
              src={homeBackground}
              alt="Home Background"
              className={styles.authorImage}
            />
          </div>
        </div>

        <div className={styles.backgroundCircles}>
          <CircleOutline
            isBlurVisible={true}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          />
          <CircleBlur
            initialScale={0.1}
            animateScale={1}
            initialOpacity={0}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroWhiteBlurCirce}
          />
        </div>
      </div>
    </InnerWrapper>
  );
};

export default AuthorIntro;
