import React, { FC } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import SingleAuthor from "../components/AuthorsUiComponents/SingleAuthor/SingleAuthor";
import { SEO } from "../components/seo";

type Props = {
  data: any;
};

const ReportTemplate: FC<Props> = ({ data }) => {
  const member = data;

  return (
    <Layout location="/blog">
      <SEO
        title={member.author.name + " " + member.author.position}
        description={member.author.about[0]?.children[0]?.text}
        type="website"
      />
      <SingleAuthor data={member} />
    </Layout>
  );
};

export default ReportTemplate;

export const query = graphql`
  query ($slug: String!) {
    author: sanityAuthor(name: { eq: $slug }) {
      _rawImage(resolveReferences: { maxDepth: 20 })
      about {
        _type
        children {
          text
          marks
          _type
          _key
        }
      }
      id
      activeMember
      image {
        asset {
          gatsbyImageData(fit: FILLMAX)
        }
      }
      alt
      linkedin
      github
      name
      position
    }
    posts: allSanityBlog(
      filter: { author: { name: { eq: $slug } } }
      sort: { order: DESC, fields: publishedAt }
      limit: 5
    ) {
      edges {
        node {
          id
          title
          publishedAt
          blogType {
            title
          }
        }
      }
    }
    posts2: allSanityBlog(
      filter: { authorTwo: { name: { eq: $slug } } }
      sort: { order: DESC, fields: publishedAt }
      limit: 5
    ) {
      edges {
        node {
          id
          title
          publishedAt
          blogType {
            title
          }
        }
      }
    }
  }
`;
