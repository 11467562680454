// extracted by mini-css-extract-plugin
export var heroBackgroundInnerCircleGreen = "SingleAuthor-module--heroBackgroundInnerCircleGreen--q3OQ7";
export var introSectionContainer = "SingleAuthor-module--introSectionContainer--iO4Oe";
export var postDate = "SingleAuthor-module--postDate--RHYDf";
export var postItem = "SingleAuthor-module--postItem--EzzXb";
export var postTitle = "SingleAuthor-module--postTitle--cueyV";
export var singleAuthorAbout = "SingleAuthor-module--singleAuthorAbout--HXuW1";
export var singleAuthorBlogs = "SingleAuthor-module--singleAuthorBlogs--vtRaM";
export var singleAuthorPosts = "SingleAuthor-module--singleAuthorPosts--2kTvy";
export var singleAuthors = "SingleAuthor-module--singleAuthors--9Fu8c";
export var singleAuthorsContent = "SingleAuthor-module--singleAuthorsContent--hY13C";